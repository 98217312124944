<template>
  <validation-observer class="relative" rules="required" v-slot="{ handleSubmit }">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="flex flex-col gap-8 mb-32 w-full relative justify-start h-full p-5">
        <div class="flex justify-start items-center gap-6 -ml-3">
          <div class="border-r border-romanSilver h-6 flex">
            <back-button label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold">Leave</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>

        <div class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-x-8 gap-y-3">
            <div v-if="$route.params.mode === 'view'" class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Leave Year</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.leaveYear.getFullYear() }}</span>
              </div>
            </div>
            <div v-else class="text-darkPurple flex flex-col gap-2">
              <span class="font-normal text-sm">Leave Year</span>
              <div class="border border-romanSilver rounded-md">
                <datepicker
                  placeholder="Select Year"
                  input-class="date-input"
                  style="width:100%"
                  minimum-view="year"
                  maximum-view="year"
                  format="yyyy"
                  :disabled-dates="disabledDates"
                  :required="true"
                  :rules="['required']"
                  v-model="payload.leaveYear"
                  @input="getLeavePlanInformation"
                />
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">{{ leaveYear }} Leave Entitlement (Days)</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.leaveDays }} Days</span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">{{ leaveYear - 1 }} Carry Forward Balance</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.carriedForwardBalance }} Days</span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Total Leave Balance ({{ leaveYear }})</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.leaveBalance }} Day(s)</span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Total Planned Leave (Days)</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.totalPlannedLeaveDays }} Days</span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Total Unplanned Leave (Days)</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">{{ payload.totalUnplannedLeaveDays }} Days</span>
              </div>
            </div>
          </div>

          <c-card class="w-full flex flex-col p-5 pt-0">
            <CardFooter
              printcard
              reloadcard
              :show-search="false"
              @reload="onQueryFn({}, null, reload)"
              @actionModal="onFilter()"
              @sortType="onQueryFn({ sort: $event }, null, getLeaveTypes)"
              @searchResult="onQueryFn({ search: $event }, null, getLeaveTypes)"
            >
              <template v-slot:rightItems>
                <div class="ml-3">
                  <c-button
                    label="Add Leave"
                    variant="primary btn-bg button-style shadow hover:bg-dynamicBackBtn hover:opacity-90"
                    :disabled="isLoading || isFetching || $route.params.mode === 'view'"
                    @onClick="$refs.ref_add_leave.onOpen()"
                  />
                </div>
              </template>
            </CardFooter>
          </c-card>

          <c-table
            :headers="headers"
            :items="payload.details"
            aria-label="annual leave plan table"
            :loading="isFetching"
            :has-number="false"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.categoryId" class="text-darkPurple text-sm font-normal">
                {{ item.data.category.name }}
              </span>
              <span v-if="item.details" class="text-darkPurple text-sm font-normal">
                {{ item.data.details }}
              </span>
              <span v-if="item.startDate" class="text-darkPurple text-sm font-normal">
                {{ $DATEFORMAT(new Date(item.data.startDate), "MMMM dd, yyyy") }}
              </span>
              <span v-if="item.endDate" class="text-darkPurple text-sm font-normal">
                {{ $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy") }}
              </span>
              <span v-if="item.numberOfDays" class="text-darkPurple text-sm font-normal">
                {{ item.data.numberOfDays }}
              </span>
              <span v-if="item.allowanceRequired" class="text-darkPurple text-sm font-normal">
                {{ item.data.allowanceRequired ? 'Yes': 'No' }}
              </span>
              <span v-if="item.updatedAt" class="text-darkPurple text-sm font-normal">
                {{ $DATEFORMAT(new Date(item.data.updatedAt), 'MMMM dd, yyyy') }} <br />
                <span class="text-xs font-semibold text-blueCrayola">{{ $DATEFORMAT(new Date(item.data.updatedAt), 'hh:mm a') }}</span>
              </span>
              <template v-if="$route.params.mode !== 'view'">
                <c-menu v-if="item.id" left @click.stop="">
                  <template v-slot:title>
                    <c-icon icon-name="more_icon" size="xs" />
                  </template>
                  <div class="flex flex-col w-32 h-18 p-2 justify-start items-start">
                    <div class="w-full" @click="$refs.ref_add_leave.onOpen(item.data)">
                      <div class="hover:bg-ghostWhite w-full cursor-pointer rounded-md">
                        <div class="flex justify-start items-center p-2 gap-2 ">
                          <c-icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">Edit</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-full" @click="payload.details.splice(item.index, 1)">
                      <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                        <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                          <c-icon icon-name="delete" class-name="text-flame" size="xs" />
                          <span class="text-darkPurple text-sm leading-5 font-normal">Delete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </c-menu>
              </template>
            </template>
          </c-table>
        </div>
      </div>
      <template v-if="$route.params.mode !== 'view'">
        <div class="relative bottom-5 w-full bg-white">
          <div class="w-full fixed bottom-0 p-5 flex justify-start items-end gap-2 bg-white drop-shadow-md">
            <c-button
              label="Submit"
              variant="primary btn-bg button-style hover:bg-dynamicBackBtn hover:opacity-90"
              :disabled="isLoading || isFetching || !payload.leaveYear"
              @onClick="handleSubmit(onSubmit)"
            />
            <c-button
              label="Cancel"
              :disabled="isLoading || isFetching"
              variant="bg-transparent button-style hover:bg-cultured"
              @onClick="$router.back()"
            />
          </div>
        </div>
      </template>
    </template>

     <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @submit="isOpenFilter = false"
      />

      <AddNewLeavePlan
        ref="ref_add_leave"
        :leave-category="leaveCategory"
        :leave-plan="payload"
        @submit="payload.details.unshift($event)"
      />
  </validation-observer>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import { ValidationObserver } from "vee-validate"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"
import CIcon from "@/components/Icon"

export default {
components: {
  ValidationObserver,
    BackButton,
    Breadcrumb,
    CCard,
    CTable,
    CMenu,
    CButton,
    Datepicker,
    CardFooter,
    FilterTable,
    CIcon,

    AddNewLeavePlan: () => import('./sideActions/AddNewLeavePlan')
  },
  computed: {
    leaveYear(){
     if (this.payload.leaveYear) {
      return new Date(this.payload.leaveYear).getFullYear()
     } return new Date().getFullYear()
    },
    disabledDates() {
      return {
        to: new Date(),
        customPredictor: (date) => (
          this.leavePlanYearOptions?.includes(date.getFullYear())
        )
      };
    }
  },
  provide() {
    return {
      leavePlan: this.payload,
      updateLeavePlan: (payload) => {
        this.payload = {...this.payload, ...payload}
      },
      leaveCategories: this.leaveCategory
    }
  },
  data(){
    return {
      isOpenFilter: false,
      isFetching: false,
      isLoading: true,
      isOpenAddLeave: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      leaveItems: [],
      leaveCategory: [],
      leavePlanYearOptions: [],
      headers: [
        { title: "leave type", value: "categoryId" },
        { title: "Details", value: "details" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "Total Leave Days", value: "numberOfDays" },
        { title: "Allowance Required", value: "allowanceRequired" },
        { title: "Last Modified", value: "updatedAt" },
        { title: "", value: "id", image: this.$route.params.mode !== 'view' },
      ],
      breadcrumbs: [
        { disabled: false, text: "Leave", href: "Leave", id: "Leave" },
        { disabled: false, text: "Leave Plan", id: "LeavePlan" },
        { disabled: false, text: "Create Plan", id: "Create Plan" },
      ],
      payload: {
        leaveYear: null,
        leaveBalance: 0,
        totalPlannedLeaveDays: 0,
        totalUnplannedLeaveDays: 0,
        carriedForwardBalance: 0,
        leaveDays: 0,
        details: [],
        leavePlanIds: []
      }
    }
  },
  watch: {
    'payload.details': function(values) {
      const numberOfDays = values.map(i => i.numberOfDays).reduce((a, b) => a + b, 0)
      this.payload.totalUnplannedLeaveDays = this.payload.leaveDays - numberOfDays
      this.payload.totalPlannedLeaveDays = numberOfDays
    }
  },
  methods: {
    onQueryFn(queryParams, payload, callback){
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if(queryParams === undefined){
          this.queryParams = {}
          return callback({ ...this.queryParams, ...payload })
        }
        this.queryParams = {...this.queryParams, ...queryParams}
        return callback({ ...this.queryParams, ...payload })
      }, 1000);
    },
    onFilter(){
      this.filterOptions = [
        { header: 'Location(s)', optionsBox: this.locationOptions },
        { header: 'Function(s)', optionsBox: this.functionOptions },
        { header: 'Level(s)', optionsBox: this.levelOptions },
        { header: 'Designation(s)', optionsBox: this.designationOptions },
      ]
      this.isOpenFilter = true
    },
    onSubmit(){
      this.isLoading = true

      const payload = {
        ...this.payload,
        orgId: this.$orgId,
        userId: this.$AuthUser.id,
        leaveYear: this.payload.leaveYear.getFullYear()
      }

      if (this.$route.params.id) {
        const planId = this.$route.params.id
        this.$_updateAnnualLeavePlan({ payload, planId }).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$router.push({ name: "EssLeaveManagement" })
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      } else {
        this.$_createAnnualLeavePlan(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$router.push({ name: "EssLeaveManagement" })
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      }
    },
    getLeaveCategory() {
      const payload = { userId: this.$AuthUser.id }
      this.$_getEmployeeCategory(payload).then(({ data }) => {
        this.leaveCategory = data.leaveCategories
      })
    },
    async getLeavePlanInformation(year) {
      const payload = { userId: this.$AuthUser.id, year: year.getFullYear() }
      await this.$_getLeavePlanInformation(payload).then(({ data }) => {
        this.payload = {
          ...this.payload,
          leaveBalance: data.totalLeaveBalance ?? 0,
          leaveDays: data.maximumApplicableDays ?? 0,
          carriedForwardBalance: data.rollOverAllowedDays ?? 0,
        }
      })
    },
    async getEmployeeAnnualLeavePlan(planId) {
      const payload = { userId: this.$AuthUser.id, planId }
      await this.$_getEmployeeAnnualLeavePlan(payload).then(({ data }) => {
        this.payload = {
          ...this.payload,
          ...data.data,
          leaveYear: new Date(data.data.leaveYear, 0 , 1),
          details: data.data?.leavePlanDetails.map(item => ({
            ...item, allowanceRequired: data.data.allowanceIsRequested,
          }))
        }
      })
    },
    async getEmployeeLeavePlan(params) {
      const payload = { userId: this.$AuthUser.id, params }
      await this.$_getEmployeeLeavePlan(payload).then(({ data }) => {
        this.leavePlanYearOptions = data.data.map(item => item.leaveYear)
      })
    },
    async bootstrapModule(routeParams){
       this.isLoading = true
      await this.getLeaveCategory()
      await this.getEmployeeLeavePlan()

      if(routeParams.id) {
        await this.getEmployeeAnnualLeavePlan(routeParams.id)
      }
      if (this.payload.leaveYear) {
        await this.getLeavePlanInformation(this.payload.leaveYear)
      }
      this.isLoading = false
    },
  },
  created(){
    this.bootstrapModule(this.$route.params)
  }

}
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>